var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"header-solid h-full px-5 py-10",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: 0 }}},[_c('a-modal',{attrs:{"title":"Add Allowance"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":function($event){_vm.visible=false}}},[_vm._v(" Cancel ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" Submit ")])],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Allowance Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'allowance_name',
              { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n              'allowance_name',\n              { rules: [{ required: true, message: 'Field is required!' }] },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"Allowance Taxed"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'taxed',
              { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n              'taxed',\n              { rules: [{ required: true, message: 'Field is required!' }] },\n            ]"}]},[_c('a-select-option',{attrs:{"value":"yes"}},[_vm._v("Yes ")]),_c('a-select-option',{attrs:{"value":"no"}},[_vm._v("No")])],1)],1),_c('a-form-item',{attrs:{"label":"Frequency"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'frequency',
              { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n              'frequency',\n              { rules: [{ required: true, message: 'Field is required!' }] },\n            ]"}],attrs:{"name":"radioGroup"}},[_c('a-radio',{attrs:{"value":"oneoff"}},[_vm._v(" One off ")]),_c('a-radio',{attrs:{"value":"monthly"}},[_vm._v(" Monthly")])],1)],1),_c('a-form-item',{attrs:{"label":"Amount"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'amount',
              { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n              'amount',\n              { rules: [{ required: true, message: 'Field is required!' }] },\n            ]"}]})],1)],1)],2),_c('a-list',{attrs:{"grid":{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3 },"data-source":_vm.data.allowances},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{},[_c('a-card',{attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.amount)+" ")])],1)}}])}),_c('a-button',{attrs:{"type":"primary","block":"","icon":"plus"},on:{"click":_vm.handleOk}},[_vm._v(" Add Allowance ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }